import React from 'react';

import icons from 'utils/dictionaryIcons';

import './Toolbar.css';

interface ToolbarProps {
  title: string;
}

const Toolbar: React.FC<ToolbarProps> = ({ title }) => {
  return (
    <section className="toolbar">
      <img src={icons.logoIcon} />
      <div className="title">{title}</div>
    </section>
  );
};

export default Toolbar;
