import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend } from 'chart.js';

import { Dataset } from 'interfaces/soap';

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend);

interface ComponentProps {
  labels: string[];
  datasets: Dataset[];
}

const MultiLineStepChart: React.FC<ComponentProps> = ({ labels = [], datasets = [] }) => {
  const data = {
    labels: labels,
    datasets: datasets,
  };

  const options = {
    maintainAspectRatio: false,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    scales: {
      y: {
        min: -0.5,
        max: 3.5,
        ticks: {
          stepSize: 1,
          precision: 0,
          callback: (value) => {
            if (value === 3) {
              return 'Up';
            }
            if (value === 2) {
              return 'Down';
            }
            if (value === 1) {
              return 'Up';
            }
            if (value === 0) {
              return 'Down';
            }
          },
        },
      },
    },
    plugins: {
      legend: { position: 'top' },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => `Estado: ${tooltipItem.raw === 1 || tooltipItem.raw === 3 ? 'Up' : 'Down'}`,
        },
      },
    },
  };

  return <Line data={data} options={options} />;
};

export default MultiLineStepChart;
