import axios, { AxiosInstance } from 'axios';
import handleError from './errorHandler';

function AxiosFactory(baseUrl: string, customHeader: any): AxiosInstance {
  const axiosInstance = axios.create({
    baseURL: baseUrl,
    headers: {
      'Content-Type': 'application/json',
      ...customHeader,
    },
  });
  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      handleError(error.response && error.response.status);
      return Promise.reject(error);
    }
  );
  return axiosInstance;
}

export default AxiosFactory;
