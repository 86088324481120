import ActionCreatorFactory from 'utils/actionCreator';
import { ActionsContext } from 'interfaces/interfaces';

const actionCreator = ActionCreatorFactory('SOAP_PANEL');

const SOAP_PANEL_ACTIONS: ActionsContext = {
  UPDATE_STATE: (properties: any) => actionCreator('UPDATE_STATE', properties),
};

export default SOAP_PANEL_ACTIONS;
