import React from 'react';
import './StatusCard.css';
import { color_status } from 'constants/statusDictionary';

const formatText = (text: string, format: string) => {
  if (format === 'money') {
    const num = parseFloat(text.replace(/[^0-9.-]+/g, ''));
    if (!isNaN(num)) {
      return num.toLocaleString('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    }
  }
  if (format === 'amount') {
    const num = parseFloat(text.replace(/[^0-9.-]+/g, ''));
    if (!isNaN(num)) {
      return num.toLocaleString('es-CO');
    }
  }
  return text;
};

const StatusCard: React.FC<any> = ({ title, status, textFormat }) => {
  return (
    <div
      className="status-card"
      style={{ background: color_status[status], color: status === 'NEUTRAL' ? '#444' : 'white' }}
    >
      {formatText(title, textFormat)}
    </div>
  );
};

export default StatusCard;
