import { PanelPlugin } from '@grafana/data';
import { SimpleOptions } from './types';
import AppPanel from 'App';

export const plugin = new PanelPlugin<SimpleOptions>(AppPanel).setPanelOptions((builder) => {
  return builder
    .addTextInput({
      path: 'json_structure_panel_ejecutivo',
      name: 'Estructura dashboard panel_ejecutivo',
      description: 'Estructura dashboard panel_ejecutivo',
      defaultValue: '',
      settings: {
        useTextarea: true,
      },
    })
    .addTextInput({
      path: 'services_relationships',
      name: 'Relacion entre nombres de servicios nagios y servicios en dashboard',
      description:
        'El formato es: <Nombre de host>,<Nombre de servicio en nagios>,<Nombre de padre en dashboard>,<Nombre de item en dashboard>',
      defaultValue: '',
      settings: {
        useTextarea: true,
      },
    });
});
