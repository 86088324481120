import AxiosFactory from './axiosFactory';

import { recoverOriginalContent } from '../utils/decompress';

const axiosInstance = AxiosFactory('https://dashboards.dparadig.com:8099', {
  'API-Token': '6lRLiOeUmVoCp5HuXzUJc0hyCiTwR_t7',
});

const getInfoServices = async () => {
  try {
    let response;
    response = await axiosInstance.get('cns/data');
    if (response.data && response.data.compressed) {
      const decoded = recoverOriginalContent(response.data.data) || '';
      response = JSON.parse(decoded);
      return response;
    } else {
      return response.data;
    }
  } catch (error) {
    console.error('Hubo un error al hacer la solicitud GET para InfoServices.');
  }
};

export { getInfoServices };
