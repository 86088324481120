import AxiosFactory from './axiosFactory';

const axiosInstance = AxiosFactory('https://dvirtualuser.dparadig.com/api', {});

const getInfoRobots = async (robotId: number) => {
  const token = 'nMgq8dKBJ9Qqrp8TLHF6qfWEkagML6kqgKmbTkKCFyVRyDDV==';
  try {
    const response = await axiosInstance.get('getRobotState', {
      params: {
        robotId: robotId,
        apiToken: token,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Hubo un error al hacer la solicitud GET para robots.');
  }
};

export { getInfoRobots };
