import React, { useContext, useEffect, useMemo, useReducer, createContext } from 'react';
import initialState from './initialState';
import actions from './actions';
import reducer from './reducer';
import useRequests from './requests';
import { CreateContextProps } from 'interfaces/interfaces';

const SistemaDeSegurosPanelContext = createContext('');

export const useSistemaDeSegurosPanelContext = () => {
  return useContext(SistemaDeSegurosPanelContext);
};

export const SistemaDeSegurosPanelContextProvider: React.FC<CreateContextProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const Requests = useRequests(state, dispatch, actions);
  const requests = Requests();

  const arrayProvider: any = useMemo(() => [state, requests, actions, dispatch], [state, requests]);

  useEffect(() => {}, []);

  return (
    <SistemaDeSegurosPanelContext.Provider value={arrayProvider}>{children}</SistemaDeSegurosPanelContext.Provider>
  );
};
