import ActionCreatorFactory from 'utils/actionCreator';
import { ActionsContext } from 'interfaces/interfaces';

const actionCreator = ActionCreatorFactory('SISTEMA_DE_SEGUROS_PANEL');

const SISTEMA_DE_SEGUROS_PANEL_ACTIONS: ActionsContext = {
  UPDATE_ROBOT: (properties: any) => actionCreator('UPDATE_ROBOT', properties),
};

export default SISTEMA_DE_SEGUROS_PANEL_ACTIONS;
