type DataRowsMap = Map<number, number>;

const getRandomColor = () => {
  return `hsl(${Math.random() * 360}, 70%, 50%)`;
};

const deleteLastParameter = (searchUrl: string) => {
  const arrayParameters = searchUrl.split('&');
  arrayParameters.pop();
  return arrayParameters.join('&');
};

const pnpNagiosReadSynth = (csvString: string): { rowsMap: DataRowsMap; timeStamps: number[] } | undefined => {
  const lines = csvString.trim().split('\n');
  const headers = lines[0].split(';').map((header: string) => header.trim());
  const timestampIndex = headers.indexOf('timestamp');
  const stepsFailMaxIndex = headers.indexOf('steps_fail_MAX');
  if (timestampIndex !== -1 && stepsFailMaxIndex !== -1) {
    let dataRows: DataRowsMap = new Map();
    const timeStamps: number[] = [];
    lines.slice(1).forEach((line: string) => {
      const cells = line.split(';').map((cell: string) => parseFloat(cell.trim()));
      const timestamp = cells[timestampIndex];
      const date = new Date(timestamp * 1000);
      date.setSeconds(0, 0);
      timeStamps.push(date.getTime());
      dataRows.set(date.getTime(), cells[stepsFailMaxIndex] === 0 ? 3 : 2);
    });
    return { rowsMap: dataRows, timeStamps: timeStamps };
  }
  return undefined;
};

const convertToMilliseconds = (timeStr: string): number => {
  const units: { [key in 's' | 'm' | 'h']: number } = { s: 1000, m: 60000, h: 3600000 };
  const number = parseInt(timeStr.slice(0, -1), 10);
  const unit = timeStr.slice(-1) as 's' | 'm' | 'h';
  return number * (units[unit] || 0);
};

export { getRandomColor, pnpNagiosReadSynth, convertToMilliseconds, deleteLastParameter };
