const wraps = {
  Incidentes: {
    title: 'Incidentes',
    row: true,
    style: { height: '12%', width: '100%' },
    items: [
      { title: 'Notificados', status_card: 'UNK', status_card_title: '0/0', style: { width: '48%' } },
      { title: 'Atendidos', status_card: 'UNK', status_card_title: '0/0', style: { width: '48%' } },
    ],
  },
  Servicios: {
    title: 'Servicios',
    style: { height: '85%', width: '100%' },
    row: true,
    items: [
      { title: 'SOAP', status_card: 'UNK', status_card_title: '0/0', redirect: 'SOAP', style: { width: '48%' } },
      { title: 'Servicio 1', status_card: 'UNK', status_card_title: '0/0', style: { width: '48%' } },
      { title: 'Servicio 2', status_card: 'UNK', status_card_title: '0/0', style: { width: '48%' } },
      { title: 'Servicio 3', status_card: 'UNK', status_card_title: '0/0', style: { width: '48%' } },
    ],
  },
  Fw: {
    title: 'Firewall y Balanceadores',
    style: { width: '48%' },
    items: [
      { title: 'Firewall', status: 'UNK' },
      { title: 'Balanceadores', status: 'UNK' },
    ],
  },
  Servidores: {
    title: 'Servidores',
    style: { width: '48%' },
    items: [
      { title: 'Windows', status: 'UNK', status_card: 'UNK', status_card_title: '0/0' },
      { title: 'Linux', status: 'UNK', status_card: 'UNK', status_card_title: '0/0' },
    ],
  },
  SSO1: {
    title: 'SSO',
    style: { width: '48%' },
    items: [
      { title: 'Antiguo', status: 'UNK', status_card: 'UNK', status_card_title: '%' },
      { title: 'Nuevo', status: 'UNK', status_card: 'UNK', status_card_title: '%' },
    ],
  },
  SSO2: {
    title: 'SSO',
    style: { width: '48%' },
    items: [
      { title: 'Uptime', status: 'UNK' },
      { title: 'Tiempo de Respuesta', status: 'UNK' },
    ],
  },
  Sintetico: {
    title: 'Monitoreo Sintético',
    style: { width: '100%' },
    row: true,
    items: [
      { title: 'SOAP', status_card: 'UNK', status_card_title: '%', redirect: 'SOAP', style: { width: '48%' } },
      { title: 'SOAP dealer', status_card: 'UNK', status_card_title: '%', style: { width: '48%' } },
      { title: 'Canal Digital - Rebalanceo', status_card: 'UNK', status_card_title: '%', style: { width: '48%' } },
      { title: 'Canal Digital - Rebalanceo', status_card: 'UNK', status_card_title: '%', style: { width: '48%' } },
      { title: 'SSO', status_card: 'UNK', status_card_title: '%', style: { width: '48%' } },
      { title: 'Cela', status_card: 'UNK', status_card_title: '%', style: { width: '48%' } },
      { title: 'Seguro Enfermedades Graves', status_card: 'UNK', status_card_title: '%', style: { width: '48%' } },
      { title: 'Pago express Wepay', status_card: 'UNK', status_card_title: '%', style: { width: '48%' } },
    ],
  },

  PAM: {
    title: 'Monitoreo PAM',
    style: { width: '30%' },
    items: [
      { title: 'Acceso web', status: 'UNK', label: 'Password safe' },
      { title: '11.11.1.11', status: 'UNK', label: 'Servidores' },
      { title: '11.11.1.11', status: 'UNK' },
      { title: 'Acceso web', status: 'UNK', label: 'PRA' },
    ],
  },
  BBDD: {
    title: 'BBDD',
    style: { width: '68%', height: '100%' },
    items: [],
  },
  Sucursales: {
    title: 'Sucursales',
    row: true,
    style: { width: '100%', height: '10%' },
    items: [
      { title: 'Norte', status: 'UNK', style: { width: '31%' } },
      { title: 'Centro', status: 'UNK', style: { width: '31%' } },
      { title: 'Sur', status: 'UNK', style: { width: '31%' } },
    ],
  },
  Central: {
    title: 'Sitio Central',
    row: true,
    style: { width: '100%', height: '10%' },
    items: [
      { title: 'El Bosque', status: 'UNK', style: { width: '31%' } },
      { title: 'Central de ...', status: 'UNK', style: { width: '31%' } },
      { title: 'Otro', status: 'UNK', style: { width: '31%' } },
    ],
  },
  Enlaces: {
    style: { width: '100%', height: '78%' },
    items: [],
  },
};

export { wraps };
