import React, { ReactNode } from 'react';
import StatusCard from '../StatusCard/StatusCard';

import { Status } from 'interfaces/soap';

import './DashboardPanel.css';

interface DashboardPanelProps {
  title: string;
  style: any;
  row?: boolean;
  status_card?: Status;
  status_card_title?: string;
  children?: ReactNode | ReactNode[];
}

const DashboardPanel: React.FC<DashboardPanelProps> = ({
  title,
  style,
  row = false,
  status_card = undefined,
  status_card_title = '',
  children = undefined,
}) => {
  return (
    <div className="dashboard-panel" style={style}>
      <div className="header">
        <div className="title">{title}</div>
        {status_card && <StatusCard title={status_card_title} status={status_card}></StatusCard>}
      </div>
      <div className={row ? 'content content-row' : 'content'}>{children}</div>
    </div>
  );
};

export default DashboardPanel;
