import actions from './actions';
import { ActionCreatorFactoryProps } from 'interfaces/interfaces';
import { SoapStructure } from 'interfaces/soap';

const updateState = (state: SoapStructure, action: ActionCreatorFactoryProps) => {
  return { ...state, ...action.payload };
};

const soapPanelReducer = (state: SoapStructure, action: ActionCreatorFactoryProps) => {
  switch (action.type) {
    case actions.UPDATE_STATE('').type:
      return updateState(state, action);

    default:
      return state;
  }
};

export default soapPanelReducer;
