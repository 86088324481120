import React, { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import SoapPanel from 'views/SoapPanel/SoapPanel';
import ExecutivePanel from 'views/ExecutivePanel/ExecutivePanel';
import SistemaDeSeguros from 'views/SistemaDeSegurosPanel/SistemaDeSegurosPanel';
import { AppProps, PanelActiveProps } from 'interfaces/interfaces';
import { SoapPanelContextProvider } from 'contexts/soapPanel';
import { SistemaDeSegurosPanelContextProvider } from 'contexts/sistemaDeSegurosPanel';

const AppRouter: React.FC<AppProps> = (props) => {
  const containerRef: any = useRef(null);
  const location = useLocation();
  const redirectTo = useNavigate();
  const params = new URLSearchParams(location.search);
  const sistemaDeSeguros = params.get('sistemaDeSeguros');
  const executivePanel = params.get('executivePanel');
  const soapPanel = params.get('soapPanel');
  const refresh = params.get('refresh') || '1m';

  const getView = () => {
    const newProps: PanelActiveProps = { ...props, location, redirectTo, refresh };

    if (executivePanel) {
      return <ExecutivePanel {...newProps} />;
    } else if (soapPanel) {
      return (
        <SoapPanelContextProvider>
          <SoapPanel {...newProps} />
        </SoapPanelContextProvider>
      );
    } else if (sistemaDeSeguros) {
      return (
        <SistemaDeSegurosPanelContextProvider>
          <SistemaDeSeguros {...newProps} />
        </SistemaDeSegurosPanelContextProvider>
      );
    } else {
      return (
        <SoapPanelContextProvider>
          <SoapPanel {...newProps} />
        </SoapPanelContextProvider>
      );
    }
  };

  useEffect(() => {
    if (!executivePanel && !soapPanel && !sistemaDeSeguros) {
      redirectTo(`${location.pathname}${location.search}&soapPanel=true`);
    }
  }, [executivePanel, soapPanel, sistemaDeSeguros, location, redirectTo]);

  useEffect(() => {
    function adjustScale() {
      const screenWidth = props.width;
      const screenHeight = props.height;

      const baseWidth = 1920;
      const baseHeight = 1000;

      const scaleX = screenWidth / baseWidth;
      const scaleY = screenHeight / baseHeight;

      const maxScale = 1.5;
      const scale = Math.min(scaleX, scaleY, maxScale);

      if (containerRef.current) {
        containerRef.current.style.transform = `scale(${scale})`;
        containerRef.current.style.transformOrigin = 'top left';
      }
    }
    adjustScale();

    window.addEventListener('resize', adjustScale);

    return () => window.removeEventListener('resize', adjustScale);
  }, [props.width, props.height]);

  return (
    <section
      ref={containerRef}
      className="panel-wrapper"
      style={{
        width: `${props.width}px`,
        height: `${props.height}px`,
        minHeight: '1000px',
        minWidth: '1920px',
        backgroundColor: '#d8e1f5',
        padding: '15px',
      }}
    >
      <section className="view-container">{getView()}</section>
    </section>
  );
};

export default AppRouter;
