import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { AppProps } from './interfaces/interfaces';
import Routes from './routes/Routes';

import './App.css';

export const AppPanel: React.FC<AppProps> = (props) => {
  const router = createBrowserRouter([
    {
      path: '/*',
      element: <Routes {...props} />,
    },
  ]);

  return <RouterProvider router={router} />;
};

export default AppPanel;
