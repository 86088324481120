const pako = require('pako');

const recoverOriginalContent = (compressedBase64: any) => {
  try {
    const compressedData = atob(compressedBase64);
    const compressedArray = new Uint8Array(compressedData.length);
    for (let i = 0; i < compressedData.length; i++) {
      compressedArray[i] = compressedData.charCodeAt(i);
    }
    const decompressedArray = pako.inflate(compressedArray);
    const originalString = new TextDecoder().decode(decompressedArray);
    return originalString;
  } catch (error) {
    console.error('Error recovering original content:', error);
    return null;
  }
};

export { recoverOriginalContent };
