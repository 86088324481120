import React, { useContext, useEffect, useMemo, useReducer, createContext } from 'react';
import initialState from './initialState';
import actions from './actions';
import reducer from './reducer';
import useRequests from './requests';
import { CreateContextProps } from 'interfaces/interfaces';

const SoapPanelContext = createContext('');

export const useSoapPanelContext = () => {
  return useContext(SoapPanelContext);
};

export const SoapPanelContextProvider: React.FC<CreateContextProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const Requests = useRequests(state, dispatch, actions);
  const requests = Requests();

  const arrayProvider: any = useMemo(() => [state, requests, actions, dispatch], [state, requests]);

  useEffect(() => {}, []);

  return <SoapPanelContext.Provider value={arrayProvider}>{children}</SoapPanelContext.Provider>;
};
