import React, { useEffect } from 'react';

import Toolbar from 'components/Toolbar/Toolbar';
import DashboardPanel from 'components/DashboardPanel/DashboardPanel';
import StatusCard from 'components/StatusCard/StatusCard';
import CardInfo from 'components/CardInfo/CardInfo';

import icons from 'utils/dictionaryIcons';
import { PanelActiveProps } from 'interfaces/interfaces';
import { convertToMilliseconds, deleteLastParameter } from 'utils/common';
import { useSistemaDeSegurosPanelContext } from 'contexts/sistemaDeSegurosPanel/index';
import { robotIds } from 'constants/sistemaDeSeguros';

import './SistemaDeSegurosPanel.css';

const SistemaDeSeguros: React.FC<PanelActiveProps> = ({ refresh, location, redirectTo }) => {
  const timeToRefresh = convertToMilliseconds(refresh);
  const [sistemaDeSegurosPanelState, sistemaDeSegurosPanelRequest] = useSistemaDeSegurosPanelContext();
  // @ts-ignore
  const { getInfoRobots } = sistemaDeSegurosPanelRequest;

  const getRobots = async () => {
    await getInfoRobots(robotIds);
  };

  useEffect(() => {
    getRobots();
    const intervalId = setInterval(getRobots, timeToRefresh);

    return () => clearInterval(intervalId);
    // eslint-disable-next-line
  }, []);

  // @ts-ignore
  function divideIntoGroups(arr, groupSize: number) {
    const result = [];
    for (let i = 0; i < arr.length; i += groupSize) {
      result.push(arr.slice(i, i + groupSize));
    }
    return result;
  }

  // @ts-ignore
  const rows = divideIntoGroups(sistemaDeSegurosPanelState.robots, 6);

  const content = rows.map((row, indexContent: number) => {
    // @ts-ignore
    const content_row = row.map((item, index: number) => {
      return (
        <CardInfo
          key={`sistema-de-seguros-card-info-${index}`}
          title={item.name}
          status_card={item.status}
          status_card_title={item.percentage}
          style={{ width: '15%' }}
        />
      );
    });
    return (
      <div key={`group-${indexContent}`} className="a">
        {content_row}
      </div>
    );
  });

  const redirectToAnotherDashboard = (panelName: string) => {
    const search = deleteLastParameter(location.search);
    redirectTo(`${location.pathname}${search}&${panelName}=true`);
  };

  const col1Style = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '5px 5px',
    padding: '15px 0px',
  };

  return (
    <section className="insurance-view">
      <Toolbar title={`Sistema de Seguros`} />
      <div className="insurance-body">
        <div className="col1">
          <DashboardPanel title={'Cotizadores'} style={col1Style}>
            <StatusCard title={'%'} status={'UNK'} />
          </DashboardPanel>
          <DashboardPanel title={'Sitio web'} style={col1Style}>
            <StatusCard title={'%'} status={'UNK'} />
          </DashboardPanel>
          <DashboardPanel title={'Botón de pago'} style={col1Style}>
            <StatusCard title={'%'} status={'UNK'} />
          </DashboardPanel>
          <DashboardPanel title={'Plataforma'} style={col1Style}>
            <StatusCard title={'%'} status={'UNK'} />
          </DashboardPanel>
        </div>
        <div className="col2">
          <DashboardPanel title={'Monitoreo Sintético'} style={{ height: '100%', padding: '5px 20px' }}>
            {content}
          </DashboardPanel>
        </div>
      </div>
      <button className="custom-hide" onClick={() => redirectToAnotherDashboard('executivePanel')}>
        <img src={icons.returnIcon} />
      </button>
    </section>
  );
};

export default SistemaDeSeguros;
