import actions from './actions';
import { ActionCreatorFactoryProps } from 'interfaces/interfaces';
import { Flow, RobotReducer, SistemaDeSeguros } from 'interfaces/sistemaDeSeguros';

const updateState = (state: SistemaDeSeguros, action: ActionCreatorFactoryProps) => {
  const robots: RobotReducer[] = [];
  const arrayFlows = action.payload.flows;
  arrayFlows.forEach((flow: Flow) => {
    let name = flow.robot.groupDescription;
    const ArrayName = name.split('_');
    ArrayName.shift();
    name = ArrayName.join(' ');
    const uptime = flow.robot.robotUptimeFactor * 100;
    let status = 'OK';
    flow.steps.forEach((step) => {
      if (step.response.startsWith('OK')) {
      } else if (step.response.startsWith('UNKNOWN')) {
        if (status === 'OK') {
          status = 'UNKNOWN';
        }
      } else {
        if (status !== 'CRITICAL') {
          status = 'CRITICAL';
        }
      }
    });
    robots.push({ name: name, status: status, percentage: uptime.toFixed(2) + '%' });
  });

  return { ...state, robots };
};

const sistemaDeSegurosPanelReducer = (state: SistemaDeSeguros, action: ActionCreatorFactoryProps) => {
  switch (action.type) {
    case actions.UPDATE_ROBOT('').type:
      return updateState(state, action);

    default:
      return state;
  }
};

export default sistemaDeSegurosPanelReducer;
