// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.insurance-view {
  height: 100%;
  padding: 20px 10px;
  background: #eff5ff;
  border-radius: 20px;
}

.insurance-body {
  display: flex;
  height: calc(100% - 76px);
}

.insurance-body .col1 {
  width: 10%;
  padding: 10px;
}

.insurance-body .col2 {
  width: 90%;
  height: 100%;
  padding: 10px;
}

.a {
  display: flex;
  justify-content: space-between;
}
`, "",{"version":3,"sources":["webpack://./views/SistemaDeSegurosPanel/SistemaDeSegurosPanel.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE,UAAU;EACV,aAAa;AACf;;AAEA;EACE,UAAU;EACV,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC","sourcesContent":[".insurance-view {\n  height: 100%;\n  padding: 20px 10px;\n  background: #eff5ff;\n  border-radius: 20px;\n}\n\n.insurance-body {\n  display: flex;\n  height: calc(100% - 76px);\n}\n\n.insurance-body .col1 {\n  width: 10%;\n  padding: 10px;\n}\n\n.insurance-body .col2 {\n  width: 90%;\n  height: 100%;\n  padding: 10px;\n}\n\n.a {\n  display: flex;\n  justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
