import { getInfoRobots } from 'rest/dvu';
import { ActionsContext } from 'interfaces/interfaces';
import { SoapStructure } from 'interfaces/soap';
import { Flow } from 'interfaces/sistemaDeSeguros';

const requests = (state: SoapStructure, dispatch: any, actions: ActionsContext) => {
  return () => ({
    getInfoRobots: async (robotIds: number[]) => {
      const flows: any = [];
      for (let index = 0; index < robotIds.length; index++) {
        const element = robotIds[index];
        const flow: Flow = await getInfoRobots(element);
        if (flow) {
          flows.push(flow);
        }
      }
      dispatch(actions.UPDATE_ROBOT({ flows } as any));
    },
  });
};

export default requests;
