import React from 'react';

import Toolbar from 'components/Toolbar/Toolbar';
import DashboardPanel from 'components/DashboardPanel/DashboardPanel';
import CardInfo from 'components/CardInfo/CardInfo';

import icons from 'utils/dictionaryIcons';
import { wraps } from 'constants/executivePanelStructure';
import { deleteLastParameter } from 'utils/common';

import './ExecutivePanel.css';

const ExecutivePanel: React.FC<any> = ({ options, location, redirectTo }) => {
  const redirectToAnotherDashboard = (panelName: string) => {
    const search = deleteLastParameter(location.search);
    redirectTo(`${location.pathname}${search}&${panelName}=true`);
  };

  function getContainer(id: string) {
    const wrap = wraps[id];
    // @ts-ignore
    const content = wrap.items.map((item, index: number) => {
      if (item.title === 'SOAP') {
        item.redirect = () => redirectToAnotherDashboard('soapPanel');
      }
      if (item.status_card) {
        return (
          <CardInfo
            key={`executive-panel-card-${index}`}
            title={item.title}
            status={item.status}
            style={item.style}
            status_card={item.status_card}
            status_card_title={item.status_card_title}
            label={item.label}
            redirect={item.redirect}
          ></CardInfo>
        );
      } else {
        return (
          <CardInfo
            key={`executive-panel-card-${index}`}
            title={item.title}
            status={item.status}
            style={item.style}
            label={item.label}
          ></CardInfo>
        );
      }
    });
    const row = wrap.row;

    return (
      <DashboardPanel
        title={wrap.title}
        style={wrap.style}
        row={row}
        status_card={wrap.status_card}
        status_card_title={wrap.status_card_title}
      >
        {content}
      </DashboardPanel>
    );
  }

  return (
    <section className="panel-view">
      <Toolbar title={`Panel Ejecutivo`} />
      <div className="body">
        <div className="col1">
          {getContainer('Incidentes')}
          {getContainer('Servicios')}
        </div>
        <div className="col2">
          <div className="custom-row">
            {getContainer('Fw')}
            {getContainer('Servidores')}
          </div>
          <div className="custom-row">
            {getContainer('SSO1')}
            {getContainer('SSO2')}
          </div>
          {getContainer('Sintetico')}
          <div className="custom-row">
            {getContainer('PAM')}
            {getContainer('BBDD')}
          </div>
        </div>
        <div className="col3">
          {getContainer('Sucursales')}
          {getContainer('Central')}
          {getContainer('Enlaces')}
        </div>
      </div>
      <button className="custom-hide" onClick={() => redirectToAnotherDashboard('sistemaDeSeguros')}>
        <img src={icons.returnIcon} />
      </button>
    </section>
  );
};

export default ExecutivePanel;
