import React from 'react';

import StatusCard from 'components/StatusCard/StatusCard';
import { color_status } from 'constants/statusDictionary';
import { Status } from 'interfaces/soap';

import './CardInfo.css';

interface CardInfoProps {
  title?: string;
  status?: Status;
  label?: string;
  style?: any;
  redirect?: any;
  textFormat?: string;
  status_card?: Status;
  status_card_title?: string;
}

const CardInfo: React.FC<CardInfoProps> = ({
  title,
  status,
  status_card = undefined,
  status_card_title = '',
  label = undefined,
  style = {},
  redirect = () => {},
  textFormat,
}) => {
  return (
    <>
      {label && <div className="custom-label">{label}</div>}
      <div className="card-info" style={style} onClick={redirect}>
        <div className="title">
          {status && <div className="status" style={{ background: color_status[status] }}></div>}
          {title}
        </div>
        {status_card && (
          <StatusCard title={status_card_title} status={status_card} textFormat={textFormat}></StatusCard>
        )}
      </div>
    </>
  );
};

export default CardInfo;
