import { getInfoServices } from 'rest/nagios';
import { ActionsContext } from 'interfaces/interfaces';
import { SoapStructure } from 'interfaces/soap';

const requests = (state: SoapStructure, dispatch: any, actions: ActionsContext) => {
  return () => ({
    getInfoServices: async () => {
      const response = await getInfoServices();
      dispatch(actions.UPDATE_STATE({ ...response } as any));
    },
  });
};

export default requests;
