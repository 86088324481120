import React, { useEffect, useState } from 'react';
import ReactSpeedometer from 'react-d3-speedometer';

import Toolbar from 'components/Toolbar/Toolbar';
import CardInfo from 'components/CardInfo/CardInfo';
import DashboardPanel from 'components/DashboardPanel/DashboardPanel';
import MultiLineGraph from 'components/MultiLineGraph/MultiLineGraph';
import MultiLineStepChart from 'components/MultiLineGraph/MultiLineStepChart';

import icons from 'utils/dictionaryIcons';
import { PanelActiveProps } from 'interfaces/interfaces';
import {
  SoapStructure,
  SoapHost,
  SoapDb,
  ServiceEntry,
  MachineStatus,
  Host,
  Service,
  ServiceTemp,
  DataStructureApiMetrics,
  NRQLResult,
  Dataset,
  Status,
  ServiceQuery,
  LastResponseTime,
} from 'interfaces/soap';
import { global_states } from 'constants/statusDictionary';
import { useSoapPanelContext } from 'contexts/soapPanel/index';
import { soapData, soapHosts, soapServices, soapBD } from 'constants/soap';
import { getRandomColor, pnpNagiosReadSynth, convertToMilliseconds, deleteLastParameter } from 'utils/common';

import './SoapPanel.css';

const SoapPanel: React.FC<PanelActiveProps> = ({ refresh, location, redirectTo }) => {
  const timeToRefresh = convertToMilliseconds(refresh);
  const [soapInfo, setSoapInfo] = useState(soapData);
  const [soapGraphData, setSoapGraphData] = useState<{
    labels: string[];
    datasets: Dataset[];
  }>({
    labels: [],
    datasets: [],
  });
  const [soapSynthData, setSoapSynthData] = useState<{
    labels: string[];
    datasets: Dataset[];
  }>({
    labels: [],
    datasets: [],
  });

  const [executivePanelState, executivePanelRequest] = useSoapPanelContext();
  // @ts-ignore
  const { getInfoServices } = executivePanelRequest;

  const getServices = async () => {
    await getInfoServices();
  };

  useEffect(() => {
    getServices();
    const intervalId = setInterval(getServices, timeToRefresh);

    return () => clearInterval(intervalId);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getBackendInfo();
    // eslint-disable-next-line
  }, [executivePanelState]);

  function isSoapStructure(obj: any): obj is SoapStructure {
    return 'api_metrics' in obj && 'nagios' in obj && 'pnp_nagios' in obj;
  }

  async function getBackendInfo() {
    let infoPanelState: SoapStructure = {
      api_metrics: {},
      nagios: {},
      pnp_nagios: {},
    };

    if (isSoapStructure(executivePanelState)) {
      infoPanelState = executivePanelState;
    }

    if (Object.keys(infoPanelState.nagios).length) {
      const nagiosData = infoPanelState.nagios;
      const apiMetrics = infoPanelState.api_metrics;
      const arrayMachineStateTemp: MachineStatus[] = [];

      soapHosts.forEach((hostItem: SoapHost) => {
        const hostName = hostItem.name;
        if (hostItem.datasource === 'nagios') {
          const host: Host = nagiosData[hostName];
          if (host) {
            return arrayMachineStateTemp.push({
              name: `${hostName} (${host.address})`,
              status: global_states[host.state],
            });
          }
        } else if (hostItem.datasource === 'nr') {
          const host: ServiceEntry = apiMetrics[hostName];
          const alerts = host?.hosts?.query?.data?.actor?.account?.nrql?.results || [];
          let status = 0;

          alerts.forEach((alert: any) => {
            if (alert.priority === 'warning' && status === 0) {
              status = 1;
            } else if (alert.priority === 'critical') {
              status = 2;
            }
          });

          return arrayMachineStateTemp.push({
            name: hostName,
            status: global_states[status],
          });
        }
        return arrayMachineStateTemp.push({
          name: hostName,
          status: 'UNKNOWN',
        });
      });

      const bdSoap = nagiosData['CNSSQL02'] || [];
      const arrayDatabaseSOAPTemp = soapBD.map((dbstate: SoapDb) => {
        const nagiosSoapService = bdSoap.services.find((srv: Service) => srv.display_name === dbstate.serviceName);
        if (nagiosSoapService) {
          const perfData = nagiosSoapService.perf_data;
          return {
            ...dbstate,
            info: perfData.split('=')[1],
            status: global_states[nagiosSoapService.state],
          };
        } else {
          return dbstate;
        }
      });
      const srvRate = nagiosData['CNSSQL02'].services.find(
        (srv: Service) => srv.display_name === 'SQLServer Query: SOAP_TASA_TRANSITORIA_HORA'
      );
      let rate = 0;
      if (srvRate && srvRate.perf_data) {
        const perfData = srvRate.perf_data.split('=');
        if (perfData.length > 1) {
          rate = parseInt(perfData[1], 10);
        }
      }
      //Graph data
      let graphResponseTimeEntities: DataStructureApiMetrics = {};
      let ServiceEntities: { [key: string]: ServiceQuery } = {};
      Object.entries(apiMetrics).forEach(([key, service]) => {
        if (service.response_time) {
          graphResponseTimeEntities[key] = service;
        }
        if (service.services) {
          ServiceEntities[key] = service.services;
        }
      });

      const allTimestamps: number[] = Array.from(
        new Set(
          Object.values(graphResponseTimeEntities).flatMap((service: ServiceEntry) =>
            service.response_time?.query?.data?.actor?.account?.nrql?.results.map((entry: NRQLResult) => {
              const date = new Date(entry.beginTimeSeconds * 1000);
              date.setSeconds(0, 0);
              return date.getTime();
            })
          )
        )
      )
        .filter((timestamp): timestamp is number => timestamp !== undefined)
        .sort((a: number, b: number) => a - b);

      const formattedTimestamps: string[] = allTimestamps.map((date) => {
        const formattedTime = new Date(date);
        const hours = formattedTime.getHours();
        const minutes = formattedTime.getMinutes();
        return `${hours}:${String(minutes).padStart(2, '0')}`;
      });

      const datasetsResponseTimes: Dataset[] = [];
      const lastResponseTimes: LastResponseTime[] = [];
      const linkResponseTime: { [key: string]: string } = {
        'BFF SOAP': 'https://onenr.io/0gR7Mn6qZQo',
        'Consorcio SOAP': 'https://onenr.io/0LREqAd0nRa',
        Devetel: 'https://onenr.io/02R5mA1E0Rb',
        Webpay: 'https://onenr.io/0vjAYogE3jP',
      };
      Object.entries(graphResponseTimeEntities).forEach(([key, service]: [string, ServiceEntry]) => {
        const dataMap = new Map<number, number | null>(
          service.response_time?.query?.data?.actor?.account?.nrql?.results.map((entry: NRQLResult) => {
            const date = new Date(entry.beginTimeSeconds * 1000);
            date.setSeconds(0, 0);
            let data = null;
            if (entry['Response time']) {
              data = entry['Response time'];
            }
            return [date.getTime(), data];
          })
        );

        datasetsResponseTimes.push({
          label: key,
          data: allTimestamps.map((timestamp) => dataMap.get(timestamp) || null),
          borderColor: getRandomColor(),
          backgroundColor: getRandomColor(),
          fill: false,
          spanGaps: true,
        });

        const results: NRQLResult[] = service.response_time?.query?.data?.actor?.account?.nrql?.results || [];
        const lastResult = results[results.length - 1];
        lastResponseTimes.push({
          name: key,
          status: 'OK',
          time: `${lastResult && lastResult['Response time'] ? lastResult['Response time'].toFixed(2) : 0} ms`,
          link: linkResponseTime[key],
        });
      });
      setSoapGraphData({
        labels: formattedTimestamps,
        datasets: datasetsResponseTimes,
      });

      const arrayServicesTemp: ServiceTemp[] = [];
      soapServices.forEach((service: any) => {
        const serviceName = service.name;
        const serviceData = apiMetrics[serviceName];
        if (serviceData) {
          let status: Status = 'OK';
          const alerts: NRQLResult[] = serviceData.services?.query?.data?.actor?.account?.nrql?.results || [];
          alerts.forEach((alert: NRQLResult) => {
            if (alert.priority === 'warning' && status === 'OK') {
              status = 'WARNING';
            } else if (alert.priority === 'critical') {
              status = 'CRITICAL';
            }
            if (alert.hasOwnProperty('error') && alert.error) {
              status = 'CRITICAL';
            }
          });
          arrayServicesTemp.push({
            name: serviceName,
            status,
            link: service.link,
          });
        } else {
          const serviceHosts = service.host.map((host: any) => nagiosData[host]);
          let status: Status = 'OK';

          if (!service.serviceName) {
            const hasIssue = serviceHosts.some((host: any) => {
              const hasCriticalService = host?.services?.some((srv: Service) => {
                return srv.state !== 0;
              });
              return hasCriticalService;
            });
            if (hasIssue) {
              status = 'CRITICAL';
            }
          } else {
            const hasIssue = serviceHosts.some((host: Host) => {
              if (!host?.services) {
                return false;
              }
              const svc = host.services.find((srv: Service) => srv.description === service.serviceName);
              return svc && svc.state !== 0;
            });
            if (hasIssue) {
              status = 'CRITICAL';
            }
          }

          arrayServicesTemp.push({
            name: serviceName,
            status: status,
            link: service.link,
          });
        }
      });
      const arrayServicesSorted = arrayServicesTemp.sort((srv: ServiceTemp, srv2: ServiceTemp) => {
        if (srv.status === 'OK') {
          return -1;
        }
        if (srv2.status === 'OK') {
          return 1;
        }
        if (srv.status === 'WARNING') {
          return -1;
        } else {
          return 1;
        }
      });

      setSoapInfo((soapData: any) => {
        return {
          ...soapData,
          arrayMachineState: arrayMachineStateTemp,
          arrayDatabaseState: arrayDatabaseSOAPTemp,
          arrayResponseTime: lastResponseTimes,
          arrayServiceState: arrayServicesSorted,
          rate: rate,
        };
      });

      const synthNRData: NRQLResult[] =
        apiMetrics['Sintetico New Relic']?.services?.query?.data?.actor?.account?.nrql?.results || [];
      const synthDataset: Dataset[] = [];
      const pnpNagiosData = pnpNagiosReadSynth(infoPanelState.pnp_nagios['Sintetico DVU']);
      if (pnpNagiosData && synthNRData) {
        const Timestamps: number[] = Array.from(
          new Set([
            ...synthNRData.map((entry: NRQLResult) => {
              const date = new Date(entry.timestamp);
              date.setSeconds(0, 0);
              return date.getTime();
            }),
            ...pnpNagiosData.timeStamps.map((timestamp: number) => {
              const date = new Date(timestamp);
              date.setSeconds(0, 0);
              return date.getTime();
            }),
          ])
        ).sort((a: number, b: number) => a - b);

        const formattedTimestamps2: string[] = Timestamps.map((date: number) => {
          const formattedTime = new Date(date);
          const hours = formattedTime.getHours();
          const minutes = formattedTime.getMinutes();

          return `${hours}:${String(minutes).padStart(2, '0')}`;
        });

        const dataMap = new Map(
          synthNRData.map((entry: NRQLResult) => {
            const date = new Date(entry.timestamp);
            date.setSeconds(0, 0);
            const data = entry.error === '' ? 1 : 0;

            return [date.getTime(), data];
          })
        );
        const nrColor = getRandomColor();
        synthDataset.push({
          label: 'Sintetico New Relic',
          data: Timestamps.map((timestamp) => dataMap.get(timestamp) || null),
          borderColor: nrColor,
          backgroundColor: nrColor,
          fill: false,
          spanGaps: true,
        });

        const dvuColor = getRandomColor();
        synthDataset.push({
          label: 'Sintetico DVU',
          data: Timestamps.map((timestamp) => pnpNagiosData.rowsMap.get(timestamp) || null),
          borderColor: dvuColor,
          backgroundColor: dvuColor,
          fill: false,
          spanGaps: true,
        });

        setSoapSynthData({
          labels: formattedTimestamps2,
          datasets: synthDataset,
        });
      }
    }
  }

  const redirectToAnotherDashboard = (panelName: string) => {
    const search = deleteLastParameter(location.search);
    redirectTo(`${location.pathname}${search}&${panelName}=true`);
  };

  return (
    <section className="soap-view">
      <Toolbar title={`Monitoreo Integral SOAP`} />
      <main className="main-container">
        <div className="panel-1">
          <div className="column-1">
            <DashboardPanel
              title="Tiempos de respuesta"
              row={true}
              style={{ width: '100%', padding: '5px', height: '50%' }}
            >
              <div className="border-dashed-grey-blue response-time">
                {soapInfo.arrayResponseTime.map((item, index: number) => {
                  const card = (
                    <CardInfo
                      key={`response-time-${index}`}
                      title={item.name}
                      status_card={item.status}
                      status_card_title={item.time}
                      style={{ height: '46px' }}
                    />
                  );
                  if (item.link) {
                    return (
                      <a
                        href={item.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ width: '100%' }}
                        key={`response-time-link-${index}`}
                      >
                        {card}
                      </a>
                    );
                  }
                  return card;
                })}
              </div>
              <div className="border-dashed-grey-blue" style={{ width: '70%' }}>
                <MultiLineGraph labels={soapGraphData.labels} datasets={soapGraphData.datasets} XLabel="" YLabel="" />
              </div>
            </DashboardPanel>

            <DashboardPanel title="Sintéticos" style={{ height: '50%' }}>
              <MultiLineStepChart labels={soapSynthData.labels} datasets={soapSynthData.datasets} />
            </DashboardPanel>
          </div>
          <DashboardPanel title="Estados de servicios" style={{ width: '40%' }}>
            <div style={{ overflowY: 'auto' }}>
              <div className="border-dashed-grey-blue" style={{ padding: 10 }}>
                {soapInfo.arrayServiceState.map((item, index: number) => {
                  const card = (
                    <CardInfo
                      key={`service-state-${index}`}
                      style={{ height: '38px' }}
                      title={item.name}
                      status_card={item.status}
                      status_card_title={item.time}
                    />
                  );
                  const link = item.link;
                  if (link) {
                    return (
                      <a
                        href={link}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ width: '100%' }}
                        key={`service-state-link-${index}`}
                      >
                        {card}
                      </a>
                    );
                  }
                  return card;
                })}
              </div>
            </div>
          </DashboardPanel>
        </div>
        <div className="panel-2">
          <DashboardPanel
            title="Estados de Máquinas"
            style={{ width: '20%' }}
            status_card={soapInfo.arrayMachineState.some((item) => item.status === 'CRITICAL') ? 'CRITICAL' : 'OK'}
          >
            <div className="machine-status">
              {soapInfo.arrayMachineState.map((item, index) => {
                const hostName = item.name.split(' ')[0];
                const link =
                  hostName === 'C2ME0025'
                    ? 'https://onenr.io/0dQeMV6B5Qe'
                    : `https://nagios.dparadig.com/thruk/cgi-bin/status.cgi?host=${hostName}`;
                return (
                  <a
                    href={link}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ width: '100%' }}
                    key={`machine-status-link-${index}`}
                  >
                    <CardInfo key={`machine-state-${index}`} title={item.name} status={item.status} style={{}} />
                  </a>
                );
              })}
            </div>
          </DashboardPanel>

          {/* Colummn 1 */}
          <DashboardPanel
            title="Base de datos"
            style={{
              height: '100%',
              width: '80%',
            }}
            row={true}
          >
            <div className="database-body">
              {soapInfo.arrayDatabaseState.map((item, index) => {
                return (
                  <a
                    href={`https://nagios.dparadig.com/thruk/cgi-bin/extinfo.cgi?type=2&host=CNSSQL02&service=${item.serviceName}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    key={`db-state-${index}`}
                    style={{ width: '30%', height: '40%', padding: 5 }}
                    className="border-dashed-grey-blue db-sales"
                  >
                    <CardInfo
                      label={`${item.category} ${item.type}`}
                      title={item.service}
                      style={{ height: '46px' }}
                      // @ts-ignore
                      status_card={'NEUTRAL'}
                      status_card_title={item.info}
                      textFormat={item.category === 'Ventas' ? 'money' : 'amount'}
                    />
                  </a>
                );
              })}
            </div>
            <div className='database-graphic'>
              <ReactSpeedometer
                value={soapInfo.rate}
                maxValue={60}
                currentValueText="Tasa Transitoria: #{value}"
                currentValuePlaceholderStyle={'#{value}'}
                customSegmentStops={[0, 45, 48, 60]}
                textColor={'#8492A9'}
                segmentColors={['#2BDC92', '#F9CC2B', '#ED4C5C']}
              />
            </div>
          </DashboardPanel>
        </div>
      </main>
      <button className="custom-hide" onClick={() => redirectToAnotherDashboard('executivePanel')}>
        <img src={icons.returnIcon} />
      </button>
    </section>
  );
};

export default SoapPanel;
