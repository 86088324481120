// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.panel-view {
  height: 100%;
  padding: 20px 10px;
  background: #eff5ff;
  border-radius: 20px;
}

.panel-view .body {
  height: calc(100% - 76px);
  padding: 16px;
  display: flex;
}

.panel-view .body .col1 {
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 2px 5px;
  align-items: center;
  justify-content: space-between;
}

.panel-view .body .col2 {
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 2px 5px;
  align-items: center;
  justify-content: space-between;
}

.panel-view .body .col3 {
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 2px 5px;
  align-items: center;
  justify-content: space-between;
}

.panel-view .body .col2 .custom-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./views/ExecutivePanel/ExecutivePanel.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,aAAa;EACb,aAAa;AACf;;AAEA;EACE,UAAU;EACV,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,gBAAgB;EAChB,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,UAAU;EACV,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,gBAAgB;EAChB,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,UAAU;EACV,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,gBAAgB;EAChB,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,WAAW;AACb","sourcesContent":[".panel-view {\n  height: 100%;\n  padding: 20px 10px;\n  background: #eff5ff;\n  border-radius: 20px;\n}\n\n.panel-view .body {\n  height: calc(100% - 76px);\n  padding: 16px;\n  display: flex;\n}\n\n.panel-view .body .col1 {\n  width: 40%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  padding: 2px 5px;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.panel-view .body .col2 {\n  width: 30%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  padding: 2px 5px;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.panel-view .body .col3 {\n  width: 30%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  padding: 2px 5px;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.panel-view .body .col2 .custom-row {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
